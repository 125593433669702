<template>
  <v-expansion-panel>
    <v-expansion-panel-header>Remove disguise</v-expansion-panel-header>
    <v-expansion-panel-content>
      <!---BOC:[error]-->
      <AError :api="api" />
      <!--EOC-->
      <div class="d-flex">
        <v-spacer />
        <v-btn
          :disabled="api.isLoading"
          color="primary"
          @click="undisguise"
          :loading="api.isLoading"
          >Confirm</v-btn
        >
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: {
    //
  },
  data: () => ({
    //BOC:[api]
    api: new Api(),
    //EOC
  }),
  watch: {
    //
  },
  created() {
    //BOC:[api]
    this.api.setMethod(`POST`);
    this.api.setParams({
      email: this.auth.email,
    })
    this.api.setUrl(
      `${this.$service.sso}/v1/en/testing/internalRole/undisguise`
    );
    this.api.setCallbackCompleted((resp) => {
      this.$store.commit("updateAuth", resp);
      this.$router.push({ name: "PageConsoleDashboard" });
      this.$store.dispatch("stopDisguise");
      this.$store.dispatch("showMessage", `Removed disguise.`);
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    undisguise() {
      this.api.fetch();
    },
  },
};
</script>