<template>
  <v-container>
    <!--BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <!--BOC:[title]-->
    <APageTitle title="SSO"/>
    <!--EOC-->
    <v-expansion-panels>
      <CardRoleDisguise/>
      <CardRoleUndisguise/>
    </v-expansion-panels>
  </v-container>
</template>

<script>
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
//EOC
import CardRoleDisguise from "./PageServiceTestingSSO/CardRoleDisguise"
import CardRoleUndisguise from "./PageServiceTestingSSO/CardRoleUndisguise"
import { mapState } from "vuex";
export default {
  components: {
    CardRoleDisguise,
    CardRoleUndisguise,
  },
  computed: {
    ...mapState({
      debugMode: (state) => state.debugMode.data,
    }),
  },
  props: {
    //
  },
  data: () => ({
    //BOC:[breadcrumb]
    breadcrumb: new Breadcrumb(),
    //EOC
  }),
  watch: {
    //
  },
  created() {
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Testing",
      to: { name: "PageServiceDashboard", params: { serviceKey: "testing" } },
      exact: true,
    });
    this.breadcrumb.add({
      text: "SSO",
      to: { name: "PageServiceTestingSSO" },
      exact: true,
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>