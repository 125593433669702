<template>
  <v-expansion-panel>
    <v-expansion-panel-header>Disguise as other role</v-expansion-panel-header>
    <v-expansion-panel-content>
      <!---BOC:[error]-->
      <AError :api="api" />
      <!--EOC-->
      <!---BOC:[error]-->
      <AError :api="apiDisguise" />
      <!--EOC-->
      <v-form>
        <v-select
          v-model="selectedInternalRoleSlug"
          label="Select a role"
          :items="items"
          item-text="name"
          item-value="slug"
          :loading="api.isLoading"
        />
      </v-form>
      <div class="d-flex">
        <v-spacer />
        <v-btn
          :disabled="api.isLoading"
          color="primary"
          @click="disguise"
          :loading="apiDisguise.isLoading"
          >Disguise</v-btn
        >
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: {
    //
  },
  data: () => ({
    //BOC:[api]
    api: new Api(),
    //EOC
    //BOC:[apiDisguise]
    apiDisguise: new Api(),
    //EOC
    //BOC
    items: null,
    selectedInternalRoleSlug: null,
    //EOC
  }),
  watch: {
    //
  },
  created() {
    //BOC:[api]
    this.api.setUrl(`${this.$service.sso}/v1/en/console/model/internalRole`);
    this.api.setCallbackCompleted((resp) => {
      this.items = resp.data;
    });
    this.api.fetch();
    //EOC
    //BOC:[apiDisguise]
    this.apiDisguise.setMethod(`POST`);
    this.apiDisguise.setUrl(
      `${this.$service.sso}/v1/en/testing/internalRole/disguise`
    );
    this.apiDisguise.setCallbackCompleted((resp) => {
      this.$store.commit("updateAuth", resp);
      this.$router.push({ name: "PageConsoleDashboard" });
      this.$store.dispatch("startDisguise");
      this.$store.dispatch("showMessage", `Disguised as ${resp.roles[0].name}`);
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    disguise() {
      this.apiDisguise.setParams({
        slug: this.selectedInternalRoleSlug,
        email: this.auth.email,
      });
      this.apiDisguise.fetch();
    },
  },
};
</script>